
import { Component, Vue } from 'vue-property-decorator'

let timeInfo: any = null
@Component
export default class Detail extends Vue {

  private count = 10
  get productId () {
    return this.$route.params.id || ''
  }

  created () {
    if (this.productId) {
      timeInfo = setInterval(() => {
        if (this.count === 1) {
          this.$router.push({ path: '/productList' })
        }
        this.count--
      }, 1000)
    } else {
      this.$router.push({ path: '/productList' })
    }
  }

  clearTime () {
    if (timeInfo) {
      clearInterval(timeInfo)
    }
  }

  clickDetail () {
    this.clearTime()
    this.$router.push({ path: '/productList/detail' + this.productId + '/child' })
  }

  clickCommit () {
    this.clearTime()
    this.$router.push({ path: '/productList' })
  }
}
